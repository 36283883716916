/* global.css */
body {
    margin: auto;
    width: auto;
    text-align: center;
    font-family: Arial, sans-serif;
    background-color: #3e3e3e;
    color: #ddd;
    width: 100vw;
    height: 100%;
}

li {
  text-align: left;
}

h3 {
  text-align: center;
}

.container {
    width: 90%;
    max-width: 1200;
    margin:auto;
}

p {
  text-align: justify;
  text-justify: inter-word; 
}


/* Base link color */
a {
    color: #90EE90; /* Light Green */
    text-decoration: none;
    transition: color 0.3s ease; /* Smooth transition for color change */
  }
  
  /* Hover state */
  a:hover {
    color: #76C76B; /* A slightly darker shade of green for contrast */
    text-decoration: underline; /* Optional: adds underline on hover */
  }
  
  /* Active state */
  a:active {
    color: #5DA356; /* Even darker green to indicate the link is being clicked */
  }
  
  /* Visited state */
  a:visited {
    color: #B0C4DE; /* Light Steel Blue for a soft, distinguishable look */
  }

.hidden {
  display: none;
  visibility: hidden;
}


/* Style for devices with a width less than 600px */
@media (max-width: 599px) {
  .container {
    padding: 20px;
  }
  .hidden-mobile {
    display: none;
    visibility: hidden;
  }
  .column-fold {
    flex-direction: column-reverse;
    display: flex;
    justify-content: space-between;
  }
  .full-mobile {
    width: 100%;
  }
}

/* Style for devices with a width of 600px and above */
@media (min-width: 600px) {
  .container {
    padding: 40px;
  }
  .column-fold {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }
  .full-mobile {
    width: 40%; 
  }
}

.doc-box {
  height: 100%;
  width: 100%;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border-left-color: #90EE90;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}